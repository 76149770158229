<template>
  <div class="getAppoint">
    <el-dialog title="选择用户" top="5px" :visible.sync="isVisible" :show-close="false" @close="toBack">
      <span class="temp_class_span_div">-738</span>
      <el-form>
        <!-- 地区第一层 -->
        <el-form-item style="margin-bottom:20px">
          <!-- 地-->
          <span style="margin-right:20px">地区:</span>
          <el-select v-model="form.province_id" placeholder="请选择省份" style="margin-right:20px;width:120px">
            <el-option v-for="item in provinceList" :key="item.id" :label="item.name" :value="item.id" @click.native=" getCity(item.id)">
            </el-option>
          </el-select>
          <!-- 市 -->
          <el-select v-model="form.city_id" placeholder="请选择地市" style="margin-right:20px;width:120px">
            <el-option v-for="item in cityList" :key="item.id" :label="item.name" :value="item.id" @click.native="getAddress(item.id)">
            </el-option>
          </el-select>
          <!-- 市 -->
          <el-select v-model="form.county_id" placeholder="请选择县" class="sid" style="width:130px">
            <el-option v-for="item in addressList" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>

          <!-- ________________________ -->
          <!-- 学段 -->
          <span style="margin-left:20px;margin-right:20px">学段学科:</span>
          <el-select v-model="form.stage_id" placeholder="请选择学段" class="sid" style="margin-right:20px;width:130px">
            <el-option v-for="item in stageList" :key="item.id" :label="item.name" :value="item.id" @click.native="getXK(item.id)">
            </el-option>
          </el-select>
          <!-- 学科 -->
          <el-select v-model="form.subject_id" placeholder="请选择学科" class="sid" style="width:130px">
            <el-option v-for="item in getDataList" :key="item.subjectId" :label="item.subjectName" :value="item.subjectId">
            </el-option>
          </el-select>
        </el-form-item>
        <!-- 教研组第二层 -->
        <el-form-item>
          <div class="searchBox">
            <span style="margin-right:-8px">教研组：</span>
            <el-select v-model="form.group_id" placeholder="请选择教研组">
              <el-option v-for="item in selectGroup" :key="item.id" :label="item.name" :value="item.id">
              </el-option>
            </el-select>
            <span style="margin-left:10px">教师：</span>
            <el-input v-model="form.realname" placeholder="请选择输入姓名" style="width:200px"></el-input>
            <div style="margin-left:173px">
              <el-button type="primary" @click="searchAll()">查询</el-button>
              <el-button @click="ClearAll">重置</el-button>
            </div>
          </div>
        </el-form-item>
        <!-- 第三层勾选 本校成员  查看已选成员 -->
        <el-form-item style="box-sizing: border-box;height:10px">
          <el-radio-group v-model="form.is_org">
            <el-radio v-for="item in radioList" :key="item.id" :value="item.id" :label="item.id" @click.native="selectShow(item.id)">
              {{item.name}}
            </el-radio>

          </el-radio-group>
          (<span style="color:red">{{selectSum}}</span>)
        </el-form-item>
        <!-- 第四层表格-->
        <el-form-item>
          <div style="border:1px solid #EBEEF5;width:92%;margin-top:20px;margin-bottom:20px">
            <el-table ref="tableBox" :data="tableList" tooltip-effect="dark" style="width: 100%" @selection-change="handleSelectionChange"
              :row-key="getRowKeys">
              <el-table-column type="selection" width="50px" :reserve-selection="true">
              </el-table-column>

              <el-table-column label="姓名" prop="realname">
              </el-table-column>

              <el-table-column label="学段" prop="stage_name">
              </el-table-column>

              <el-table-column label="学科" prop="subject_name">
              </el-table-column>
              <el-table-column label="所属教研组" prop="group_name" :show-overflow-tooltip="true">
              </el-table-column>

              <el-table-column label="所属单位" prop="org_name">
              </el-table-column>

              <el-table-column label="地区" prop="area">
              </el-table-column>
            </el-table>
          </div>
          <!-- 分页 -->
          <el-form-item v-if="num ==1">
            <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="this.form.page"
              :page-sizes="[10, 20, 30, 40]" :page-size="this.form.limit" layout="total, sizes, prev, pager, next, jumper" :total="total">
            </el-pagination>

          </el-form-item>
          <el-form-item v-if="num ==2">
            <el-pagination background @size-change="handleSizeChange1" @current-change="handleCurrentChange1" :current-page="this.form1.page"
              :page-sizes="[10, 20, 30, 40]" :page-size="this.form1.limit" layout="total, sizes, prev, pager, next, jumper" :total="total1">
            </el-pagination>

          </el-form-item>
          <el-button type="primary" style="margin-left:76%" @click="SelectCancel()">确定</el-button>
          <el-button @click="toBack()">取消</el-button>
        </el-form-item>
        <!-- table的end -->
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
export default {
  //getLiveMember为修改直播初始化人员的ids字符串
  props: ["isOk", "getLiveMember", "outer_studioId"],
  data() {
    return {
      num: 1,
      //  selectListData:[],//需要回显的数据id数组
      //已选人员数量统计
      selectSum: 0,
      //会话框变量
      isVisible: false,
      //存放多选
      //  人员的id
      AppointUser_ids: [],
      //  初始分页总数
      total: 0,
      //已选分页总数
      total1: 0,
      //    初始表格数据
      tableData: [],
      //已选表格数据
      selectTableData: [],
      radioList: [
        {
          id: 1,
          name: "本校成员",
        },
        {
          id: 0,
          name: "查看已选人员",
        },
      ],

      //教研组
      selectGroup: [],
      // 地区
      provinceList: "",
      // 市
      cityList: "",
      //addressList
      addressList: "",
      //学段下拉框数组
      stageList: "",
      //学科下拉框数组
      SubjectList: "",
      //  这个是指定人员表单参数
      form: {
        limit: 10,
        page: 1,
        stage_id: "",
        subject_id: "",
        province_id: "",
        city_id: "",
        county_id: "",
        realname: "",//姓名
        is_org: "", //0不显示，1显示本校成员
        group_id: "",//选择教研组id
        user_ids: "",//选中的人员，传字符串
      },
      //这个是选中人的表单
      form1: {
        limit: 10,
        page: 1,
        stage_id: "",
        subject_id: "",
        province_id: "",
        city_id: "",
        county_id: "",
        realname: "",//姓名
        is_org: "", //0不显示，1显示本校成员
        group_id: "",//选择教研组id
        user_ids: "",//选中的人员，传字符串
      },
      //我的教研组参数
      groupList: {
        limit: 10,
        page: 1,
        my_type: 1,
        audit_status: ''
      },
    }
  },
  watch: {
    //监听传过来的变量
    isOk() {
      this.isVisible = this.isOk;
      //打开窗口，判断是否有选中人员数据,如果有，就显示人员分页，默认选中
      if (this.getLiveMember) {
        // 显示已选成员分页
        this.num = 2
        //默认选中已选成员单选按钮
        this.form.is_org = 0
        this.form1.user_ids = this.getLiveMember;
        //查询指定人员
        this.getSelectList();
        let arr9 = this.getLiveMember.split(",");
        this.selectSum = arr9.length
        arr9.forEach(row => {
          // this.$refs.tableBox.toggleRowSelection(row);
        });
      }
    },
  },
  computed: {
    // 计算学科数据
    getDataList() {
      return this.SubjectList
    },
    //计算初始表格的数据
    tableList() {
      return this.tableData;
    },
    //计算选中表格数据
    selectOneList() {
      return this.selectTableData;
    }
  },
  created() {
    // 初始化学段
    this.getXD()
    //  初始化省
    this.getProvice()
    //初始化我的教研组成员
    this.getMyGroup()
    //初始化指定成员列表
    this.getList()
  },
  methods: {
    // 测试节点
    getOnes() {
    },
    //默认选中方法
    getRowKeys(row) {
      return row.id;
    },
    //查询
    searchAll() {
      //全局页搜索
      this.form.page = ""
      this.form.is_org = ""
      this.num = 1
      this.getList();
    },
    //确定选中中人员
    SelectCancel() {
      //关闭弹框//选中人员的ids字符串、长度，关闭窗口
      this.$emit("cancleSelect", this.form1.user_ids, this.AppointUser_ids.length, false);
    },
    //取消弹出
    toBack() {
      this.$emit("closeTable", false);
    },
    //重置
    ClearAll() {
      //初始化分页
      this.num = 1;
      //清空指定人员
      this.form.user_ids = "";
      // 地区
      this.provinceList = "";
      // 市
      this.cityList = "";
      //县
      this.addressList = "";
      //学段下拉框数组
      this.stageList = "";
      //学科下拉框数组
      this.SubjectList = "";
      this.form.limit = 10;
      this.form.page = 1;
      this.form.province_id = "";
      this.form.stage_id = "";
      this.form.subject_id = "";
      this.form.province_id = "";
      this.form.city_id = "";
      this.form.county_id = "";
      // 是否显示成员
      this.form.is_org = "";
      // 选择的教研组
      this.form.group_id = "";
      this.form.realname = "";
      // 重新获取学段
      this.getXD()
      //初始化省
      this.getProvice()
      //  重新获取列表
      this.getList();
    },
    //多选中的数据
    handleSelectionChange(val) {
      this.selectSum = val.length;
      this.AppointUser_ids = val;
      let ids = this.AppointUser_ids.map((item) => {
        return item.id
      });
      this.$set(this.form1, "user_ids", ids.toString());
    },
    selectShow(id) {
      if (id == 1) {
        this.num = 1
        this.form.is_org = 1
        this.getList();
      } else if (id == 0) {
        this.num = 2
        if (this.selectSum != 0) {
          this.getSelectList();
        } else if (this.selectSum == 0) {
          this.tableData = []
        }
      }
    },
    //获取选中人员列表
    async getSelectList() {
      let res = await this.$axios.get("get_appoint_user", {
        params: this.form1
      });
      //赋值表格
      this.tableData = res.data.data.data;
      this.total1 = res.data.data.total;
    },
    //获取初始指定人员
    async getList() {
      let res = await this.$axios.get("get_appoint_user", {
        params: this.form
      });
      //赋值表格
      this.tableData = res.data.data.data;
      //统计总数
      this.total = res.data.data.total;
    },
    //  获取市
    async getProvice() {
      let res = await this.$axios.get("province");
      this.provinceList = res.data.data;
    },
    //获取区
    async getCity(id) {
      let res = await this.$axios.get("city", {
        params: {
          pid: id
        }
      });
      this.cityList = res.data.data;
    },
    //获取县
    async getAddress(id) {
      let res = await this.$axios.get("county", {
        params: {
          pid: id
        }
      });
      this.addressList = res.data.data;
    },
    //获取学段
    async getXD() {
      let res = await this.$axios.get("stage/stage_list");
      this.stageList = res.data.data;
    },
    //获取学科
    async getXK(sid) {
      let res = await this.$axios.get("subject/list", {
        params: {
          pharseid: sid
        }
      });
      this.SubjectList = res.data.data;
    },

    //获取我的教研组成员
    async getMyGroup() {
      let res = await this.$axios.get("TeacherGroup/get_my_list", {
        params: this.groupList
      })
      this.selectGroup = res.data.data.data
    },
    // 初始人员和本校成员分页
    handleSizeChange(val) {
      this.form.limit = val;
      //重新渲染
      this.getList();
    },

    handleCurrentChange(val) {
      this.form.page = val
      //重新渲染
      this.getList();
    },

    // 已选人员分页
    handleSizeChange1(val) {
      this.form1.limit = val;
      //重新渲染
      this.getSelectList();
    },

    handleCurrentChange1(val) {
      this.form1.page = val
      //重新渲染
      this.getSelectList();
    },
  }
}
</script>

<style lang="less" scoped>
.getAppoint {
  width: 1200px;
  .el-form {
    // width: 980px;
    width: 100%;
    margin-left: 20px;
    .searchBox {
      display: flex;
    }
  }
}
</style>